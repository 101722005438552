import React, { useEffect, useState } from "react";
import JoinGame from "../view/game/JoinGame";
// import { useNavigate } from "react-router-dom";
import contestApi from "../api/contest";
import { useSelector, useDispatch } from "react-redux";
import {
  useOutletContext,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import helper from "../common/Helper";
import ingamenameApi from "../api/ingamename";
import clevertap from "clevertap-web-sdk";

function JoinGameController() {
  // toast.configure

  const [isAddName, setIsAddName] = useState(false);
  const [isUserInGameName, setIsUserInGameName] = useState(false);
  const [name, setName] = useState("");
  const [gameType, setGametype] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const perems = useParams();
  const [contestDetails, setContestDetails] = useState({});
  clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
  clevertap.privacy.push({ useIP: true }); // Set the flag to true, if the user agrees to share their IP data
  clevertap.init(helper.cleverTapAccount, "in"); //
  clevertap.setLogLevel(3); // 3 = all logs
  useEffect(() => {
    (async () => {
      if (perems.contestId) {
        await getContest(perems.contestId, user?.userDeatils?.user_id);
      }
    })();
  }, [perems.contestId, user]);

  async function getContest(contestId, user = "yytyt") {
    setLoader(true);
    const list = await contestApi.getContestById(contestId, user);
    if (list.error) {
      setLoader(true);
      const error = list.error;
    } else {
      if (list.data) {
        const data = list.data;
        if (data.success) {
          if (data.data.userInGameName != null && data.data.userInGameName != "") {
            setName(data.data.userInGameName);
            setGametype(data.data.gameTypeAry.name);
            setIsUserInGameName(true);
            setIsAddName(true);
          }
          setContestDetails(data.data);
          localStorage.setItem("gameName", data.data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      } else {
        setLoader(false);
      }
    }
  }

  const setAddName = () => {
    setIsAddName(true);
  };

  const onChangeName = (e) => {
    setName(e.target.value);
  };
  const onChangeEditedName = (e) => {
    setName(e.target.value);
  };

  const registerUser = async (e) => {
    if (
      contestDetails.userInGameName &&
      contestDetails.userInGameName === name
    ) {
      callRegisterUser();
    } else {
      var payload = {
        // user: user?.userDeatils?.user_id,
        userINGameName: name,
        //  gamerID: perems?.contestId,
        game: perems?.gameId,
      };
      const addName = await ingamenameApi.add(payload);

      if (addName.error) {
        const error = addName.error;
        toast(error?.response?.data?.data[0]?.msg);
      } else {
        if (addName.data) {
          const data = addName.data;
          if (data.success) {
            callRegisterUser();
          }
        }
      }
    }
  };

  const callRegisterUser = async () => {
    let data = {};
    // data.user = user?.userDeatils?._id;
    data.inGameUserName = name;
    data.contest = perems?.contestId;
    data.joinedDate = new Date();
    data.JoinedTimestamp = new Date().getTime();
    data.isActive = true;
    data.gameName = name;
    const join = await contestApi.joinContest(data);
    if (join.error) {
      const error = join.error;
      toast(error?.response?.data?.errors[0]?.msg);
    } else {
      if (join.data) {
        const data = join.data;
        clevertap.event.push("Gamerji_GameRegistered", {
          GameName:name,
          MatchDate: contestDetails.dateTime,
          identity: user?.userDeatils?.phone,
          Date: new Date(),
        });
        if (data.success === true) {
          navigate("/registration/done/" + perems?.contestId);
          localStorage.setItem("joincontestAd", "true");
          const balance = localStorage.getItem("userBalance");
          const userProperty = {
            GemBalance: balance,
          };
          
          clevertap.event.push("Gamerji_GemDebit", {
            Amount: contestDetails?.entryFee,
            GameName:name,
            type: "Contest",
            typeId: contestDetails?._id,
            status: "Pass",
            Date: new Date(),
            userProperty: [userProperty],
          });
        }
      }
    }
  };
  return (
    <>
      <JoinGame
        setAddName={setAddName}
        isAddName={isAddName}
        isUserInGameName={isUserInGameName}
        onChangeName={onChangeName}
        onChangeEditedName={onChangeEditedName}
        name={name}
        loader={loader}
        gameType={gameType}
        registerUser={registerUser}
        contestDetails={contestDetails}
      />
      <ToastContainer />
    </>
  );
}
export default JoinGameController;
