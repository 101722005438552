import axios from "axios";
import { resolve } from "./resolver";
import helper from "../common/Helper";

function login() {
    const loginCall = async (data) => {
        return await resolve(
            axios({
                method: 'post',
                url: helper.viApiUrl() + 'login',
                responseType: 'json',
                headers: helper.headers(),
                data:data
            })
                .then(response => response)
                .catch(error => error)
        )

    }
    return {
        loginCall,
    }
}
const loginApi = login();
export default loginApi;