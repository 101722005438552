import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import validator from "validator";
import Select, { components, DropdownIndicatorProps } from "react-select";

import BackImgIcon from "../../assets/images/back-arrow.svg";
import sortIcon from "../../assets/images/sort-icon.svg";
import closeIcon from "../../assets/images/close-btn-1.svg";
import cusCare from "../../assets/images/cus_care.svg";


import DropdownInputDownArrow from "../../assets/images/dropdown_input_down_arrow.png";
import DropdownInputUpArrow from "../../assets/images/dropdown_input_up_arrow.png";
import customerCareApi from "../../api/customercare";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from "react-redux";


import './CustomerCare.css';
import { customStyles } from "./CustomStylesSelect";
import moment from "moment";
import helper from "../../common/Helper"



export default function CustomerCareTickets() {
  //console.log("props mycontest", props);

  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [ticketData, setTicketData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(4);

  const [lastMonthChecked, setLastMonthChecked] = useState(false);
  const [last6MonthChecked, setLast6MonthChecked] = useState(false);
  const [lastYearChecked, setLastYearChecked] = useState(false);
  const [moreThanYearChecked, setLastmoreThanYearChecked] = useState(true);



  const listInnerRef = useRef();
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [lastList, setLastList] = useState(false);

  // useEffect(() => {
  //   (async () => {

  //     const payload = {
  //       search: {
  //         user: user?.userDeatils?._id
  //       }
  //     }
  //     await getTicketList(payload)
  //   })();

  // }, []);

  

  useEffect(() => {
    (async () => {
            // setContestList([])
            if (!lastList && prevPage !== currPage) {
                // fetchData();
                const payload = {
                }
                await getTicketList(payload)

            }


    })()
}, [currPage, lastList, prevPage, ticketData])


  useEffect(() => {

    (async () => {
      console.log("selectedOption", selectedOption);
      setLastMonthChecked(false)
      setLast6MonthChecked(false)
      setLastYearChecked(false)
      setLastmoreThanYearChecked(false)
      setTicketData([])

      const payload = {
      }

      if (selectedOption === 1)//last month
      {
        setTicketData([])
        setLastMonthChecked(true)
        payload.search.fromDate = moment().subtract(1, 'month').startOf('month').toDate()
        payload.search.toDate = moment().subtract(1, 'month').endOf('month').toDate()

      }
      else if (selectedOption === 2)//last 6 month
      {
        setTicketData([])
        setLast6MonthChecked(true)
        payload.search.fromDate = moment().subtract(6, 'month').startOf('month').toDate()
        payload.search.toDate = moment().subtract(1, 'month').endOf('month').toDate()

      }
      else if (selectedOption === 3)//last year
      {
        setTicketData([])
        setLastYearChecked(true)
        payload.search.fromDate = moment().subtract(1, 'year').startOf('year').toDate()
        payload.search.toDate = moment().subtract(1, 'year').endOf('year').toDate()

      }
      else // more than year
      {
        setTicketData([])  
        setLastmoreThanYearChecked(true)

      }
      console.log("paylod", payload);
      
      await getTicketList(payload)
    })();
  }, [selectedOption]);


  async function getTicketList(payload) {

    payload.pagination = {
      pageNo: currPage,
      recordPerPage: 10,
      sortBy:'createdAt',
      sortDirection:'1'     
  }
    const list = await customerCareApi.ticketList(payload)
    console.log("list ticket", list);
    if (list.error) {
      const error = list.error
      console.log('error', error.message)
    } else {
      if (list.data) {
        const data = list.data
        if (data.success) {
          // console.log(data.data)

          if (!data.data.length) {

            setLastList(true);
            return;
        }


        setPrevPage(currPage);
        if(currPage==1){
          setTicketData(data.data)
        }else{
          setTicketData([...ticketData, ...data.data])
        }
        

        } else {

          setTicketData([])

        }
      }
    }

  }


  const onScroll = () => {
    if (listInnerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
        if (scrollTop + clientHeight === scrollHeight) {
            setCurrPage(currPage + 1);
        }
    }
};



  const goToCustChats = (ticket) => {

    console.log("ticket", ticket);
    navigate('/customer-care-Chats/' + ticket._id)
  }


  return (
    <>
      <div className="no-footer customerCare-body">

        <header class="position-fixed">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 d-flex align-items-center">
                <Link onClick={() => navigate(-1)}>
                  <img className="customer_care_back_icon" src={BackImgIcon} />
                </Link>
                <h1 class="mb-0 ms-3 text-white h2 fw-normal">customer care</h1>
              </div>
            </div>
          </div>
        </header>
        <div class="main-content">
          <section class="customerCare-Tickets">
            <div class="container-fluid"  onScroll={onScroll}
                ref={listInnerRef}>
              <div class="trouble-card card">
                <div class="card-body">
                
                  <h2 class="mb-3" style={{display:"inline-block"}}>Are you facing any trouble?</h2>
                  <img src={cusCare} alt="" style={{float:"right"}} />
                  <Link to="/customer-care">
                    {/* <img src={rightChevroncon} alt="" /> */}
                    <a class="btn btn-danger">tell us now</a>
                  </Link>
                </div>
              </div>
              <div class="d-flex justify-content-between mb-3 mt-3">
                <p style={{color:'#EE2737',fontSize:'1.00rem',marginTop:'-15px'}}>* Customer resolution will be provided within 48 hours.</p>
              </div>
              <div class="d-flex justify-content-between mb-3 mt-3">
                <h3 class="text-white mb-0 h1 fw-bold">your past tickets</h3>
                <a href="#" data-bs-toggle="modal" data-bs-target="#filterModal"><img
                  src={sortIcon} alt="" /></a>
              </div>
              {
                ticketData.length > 0 ?
                  ticketData.map((ticket, i) => {
                    return (
                      <div class="contest-card card" onClick={() => goToCustChats(ticket)} >
                        <div class="card-body">
                          <div class="card-header">
                            <div class="d-flex align-items-center justify-content-between mb-3">
                              <h2 class="text-white mb-0 fw-normal">{ticket.customerCareCategoryName}</h2>
                              {
                                ticket.status === "1" &&
                                <span class="badge badge-pending">{helper.TicketStatusTitles[ticket.status]}</span>

                              }
                              {
                                ticket.status === "4" &&
                                <span class="badge badge-resolved">{helper.TicketStatusTitles[ticket.status]}</span>

                              }
                              {
                                (ticket.status === "3" || ticket.status === "2") &&
                                <span class="badge badge-inProgress">{helper.TicketStatusTitles[3]}</span>

                              }
                              {
                                (ticket.status === "5" || ticket.status === "6") &&
                                <span class="badge badge-closed">{helper.TicketStatusTitles[6]}</span>

                              }
                              {console.log("ticket.status",ticket.status)}
                            </div>
                            <p class="text-gray mb-0">{ticket.description}</p>
                          </div>
                          <div class="card-content">
                            <div class="d-flex align-items-center justify-content-between">
                              <div class="">
                                <p class="mb-1 h4 text-muted">raised on</p>
                                
                                <h3 class="mb-0 text-white fw-bold d-flex align-items-center">{moment(ticket.createdAt).format('DD MMM YYYY, hh:mm a')}</h3>
                              </div>
                              <div class="">
                                <p class="mb-1 h4 text-muted">ticket no.</p>
                                <h3 class="mb-0 text-white fw-bold d-flex align-items-center">#{ticket.code}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                  :
                  <>

<div className="d-flex align-items-center justify-content-center">
                      <h2 className="text-white text-align-center">No Tickets Found</h2>
                    </div>
                  </>
              }

            </div>
          </section>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div class="modal fade common-modal filterModal" id="filterModal" tabindex="-1" aria-labelledby="filterModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <a href="#" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
                src={closeIcon} alt="" /></a>
              <h2>filter by duration</h2>
              <hr />
              <div class="form-check form-check-reverse mb-3 pb-1">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" data-bs-dismiss="modal" onChange={() => setSelectedOption(1)} checked={lastMonthChecked} />
                <label class="form-check-label" for="flexRadioDefault1">
                  Last month
                </label>
              </div>
              <div class="form-check form-check-reverse mb-3 pb-1">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                  data-bs-dismiss="modal" onChange={() => setSelectedOption(2)} checked={last6MonthChecked} />
                <label class="form-check-label" for="flexRadioDefault2">
                  Last 6 months
                </label>
              </div>
              <div class="form-check form-check-reverse mb-3 pb-1">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" data-bs-dismiss="modal" onChange={() => setSelectedOption(3)} checked={lastYearChecked} />
                <label class="form-check-label" for="flexRadioDefault3">
                  Last one year
                </label>
              </div>
              <div class="form-check form-check-reverse">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4"
                  data-bs-dismiss="modal" onChange={() => setSelectedOption(4)} checked={moreThanYearChecked} />
                <label class="form-check-label" for="flexRadioDefault4">
                  More than a year
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

    </>
  )
}