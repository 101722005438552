import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import validator from "validator";
import Select, { components, DropdownIndicatorProps } from "react-select";

import BackImgIcon from "../../assets/images/back-arrow.svg";

import DropdownInputDownArrow from "../../assets/images/dropdown_input_down_arrow.png";
import DropdownInputUpArrow from "../../assets/images/dropdown_input_up_arrow.png";
import customerCareApi from "../../api/customercare";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";

import "./CustomerCare.css";
import { customStyles } from "./CustomStylesSelect";
import { UserAdDisplay } from "../../common/UserAdDisplay";
// import { useAdVisibility } from "../../common/UseAdVisibility";
import { useSearchParams } from "react-router-dom";

export default function CustomerCare(props) {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  // console.log("container-fluid",user);
  const [categoryDataAll, setCategoryDataAll] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [gamerjiName, setGamerjiName] = useState("test");
  const [mobile, setMobile] = useState(user?.userDeatils?.phone);
  const [email, setEmail] = useState("");
  const [name, setName] = useState(user?.userDeatils?.name);
  const [issue, setIssue] = useState("");
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  const [selectedOption, setSelectedOption] = useState();
  const [selectedSubcategoryOption, setSelectedSunCategoryOption] = useState();

  // const { isVisible } = useAdVisibility();

  UserAdDisplay("Gamerji_CustomerCare_320x50");
  useEffect(() => {
    (async () => {
      await getCustomerCareCategoryList();
      // let temp = [
      //   { label: 'Account', value: 'Account' },
      //   { label: 'Wallet issue', value: 'Wallet issue' },
      //   { label: 'Winnings', value: 'Winnings' },
      //   { label: 'Room joining issue', value: 'Room joining issue' },
      //   { label: 'Hacking/team-up', value: 'Hacking/team-up' },
      //   { label: 'App not working properly', value: 'App not working properly' },
      //   { label: 'Account', value: 'Account' },
      //   { label: 'Wallet issue', value: 'Wallet issue' },
      //   { label: 'Winnings', value: 'Winnings' },
      //   { label: 'Room joining issue', value: 'Room joining issue' },
      //   { label: 'Hacking/team-up', value: 'Hacking/team-up' },
      //   { label: 'App not working properly', value: 'App not working properly' },
      //   { label: 'Account', value: 'Account' },
      //   { label: 'Wallet issue', value: 'Wallet issue' },
      //   { label: 'Winnings', value: 'Winnings' },
      //   { label: 'Room joining issue', value: 'Room joining issue' },
      //   { label: 'Hacking/team-up', value: 'Hacking/team-up' },
      //   { label: 'App not working properly', value: 'App not working properly' },
      // ];
      // setCategoryData(temp);
    })();
  }, [mobile, email, name, issue, selectedOption, selectedSubcategoryOption]);
  // let urlToken = searchParams.get("token");
  // console.log(urlToken)

  // ******logic for the ad when ad count would be independent *****
  const [customercareAd, setcustomercareAd] = useState(0);

  const [isVisible2, setIsVisible2] = useState(false);

 

  useEffect(() => {
    const currentTime = new Date().getTime();
    const storedCount = localStorage.getItem("customercareAd");
    const storedTimestamp = localStorage.getItem("timestamp");

    if (storedTimestamp && currentTime > storedTimestamp) {
      localStorage.setItem("customercareAd", 0);
      localStorage.setItem("timestamp", new Date());
      setcustomercareAd(0);
    } else {
      let newCount = Number(storedCount) + 1;

      // let date = new Date(new Date().getTime() + 15 * 1000);
      localStorage.setItem("customercareAd", newCount);
      // localStorage.setItem("timestamp", moment().format("DD-MM-YYYY"));
    }
    setTimeout(() => {
      // const storedCount = localStorage.getItem("customercareAd");
      const storedCount = localStorage.getItem("count");
      let newCount = Number(storedCount);
      // console.log(newCount, "newCount");
      setcustomercareAd(newCount);
    }, 2000);
  }, []);

  useEffect(() => {
    // const timeout = setTimeout(() => {
    //   if (customercareAd > 0 && customercareAd % 3 === 0) {
    //     setIsVisible2(true);
    //   } else {
    //     setIsVisible2(false);
    //   }
    // }, 10);

    // return () => clearTimeout(timeout);
    if (customercareAd >= 3) {
      setIsVisible2(true);
    } else {
      setIsVisible2(false);
    }
  }, [customercareAd]);

  useEffect(() => {
    // { console.log(user.userDeatils.name) }
    // if (gamerjiName !== undefined && gamerjiName !== null && gamerjiName !== '' &&
    if (
      mobile !== undefined &&
      mobile !== null &&
      mobile !== "" &&
      mobile.length === 10 && // already have mobile that is unchangeable
      //email !== undefined && email !== null && email !== '' && validator.isEmail(email) &&
      name !== undefined &&
      name !== null &&
      name !== "" && // already have name that is unchangeable
      //issue !== undefined && issue !== null && issue !== '' &&
      selectedSubcategoryOption &&
      selectedSubcategoryOption?.value &&
      selectedOption &&
      selectedOption?.value
    ) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
    //selectedSubcategoryOption
  }, [mobile, email, name, issue, selectedOption, selectedSubcategoryOption]);

  const onClickSubmit = async () => {
    if (selectedOption && selectedOption?.value) {
      let data = {};
      // data.name = name
      // data.phone = mobile
      data.name = user?.userDeatils?.name || name;
      data.phone = user?.userDeatils?.phone;

      data.email = email;
      // data.gamerjiUserName = gamerjiName;
      data.description = issue;
      data.customerCareCategory = selectedOption?.value;
      data.customerCareCategoryName = selectedOption?.label;
      data.customerCareSubCategory = selectedSubcategoryOption?.value;
      data.customerCareSubCategoryName = selectedSubcategoryOption?.label;
      // data.user = user?.userDeatils?._id;

      const submitCust = await customerCareApi.customerCareAdd(data);
      if (submitCust && submitCust.error) {
        toast(submitCust?.error?.message);
      } else {
        const data = submitCust.data;

        if (data.success) {
          // console.log(data.data[0].msg)
          toast(data.data[0].msg);
          navigate(-1);
        } else {
          toast(data.data[0].msg);
        }

        // if (submitCust.success) {
        //     console.log(data)
        //   } else {

        //   }
        // }
      }
    } else {
      toast("Please Select Category");
    }
    // console.log(gamerjiName, mobile, email, name)
  };

  async function getCustomerCareCategoryList() {
    let payload = {
      search: {
        onlyActive: true,
      },
      pagination: {
        pageNo: 1,
        recordPerPage: 100,
      },
    };
    const list = await customerCareApi.customerCareCategoryList(payload);
    if (list.error) {
      const error = list.error;
      console.log("error", error.message);
    } else {
      if (list.data) {
        const data = list.data;
        if (data.success) {
          // console.log(data.data)
          setCategoryDataAll(data.data);
          if (data.data.length) {
            let tempAry = [];
            data.data.map((cat, i) => {
              if (cat.isParent) {
                let obj = {};
                obj.label = cat.label;
                obj.value = cat._id;
                tempAry.push(obj);
              }
            });
            setCategoryData(tempAry);
          } else {
            setCategoryData([]);
          }
          // setFaqList(data.data)
        } else {
          setCategoryData([]);

          // setFaqList([])
        }
      }
    }
  }

  const DropdownIndicator = ({ innerRef, props, isFocused }) => (
    <div ref={innerRef} {...props} className="pe-2">
      {isFocused ? (
        <img src={DropdownInputUpArrow} />
      ) : (
        <img src={DropdownInputDownArrow} />
      )}
    </div>
  );

  const changeCategory = (e) => {
    let tempAry = [];
    categoryDataAll.map((cat, i) => {
      if (!cat.isParent && cat.parent._id == e.value) {
        let obj = {};
        obj.label = cat.label;
        obj.value = cat._id;
        tempAry.push(obj);
      }
    });
    setSubCategoryData(tempAry);
    setSelectedSunCategoryOption([]);
    setSelectedOption(e);
  };

  const changeSubCategory = (e) => {
    setSelectedSunCategoryOption(e);
  };
  const filedValidation = (filed) => {
    // if (filed === 'username' && gamerjiName === '') {
    //   toast('Gamerji Username is required');
    // }

    if (filed === "mobile" && mobile === "") {
      toast("Mobile is required");
    }
    if (filed === "mobile" && mobile && mobile.length !== 10) {
      toast("Mobile is Invalid");
    }
    /*if (filed === 'email' && email === '') {
      toast('Email is required');
    }*/
    if (filed === "email" && email && !validator.isEmail(email)) {
      toast("Email is Invalid");
    }
    if (filed === "name" && name === "") {
      toast("Name is required");
    }
    /*if (filed === 'description' && name === '') {
      toast('description is required');
    }*/
  };

  return (
    <>
      <div className="no-footer customerCare-body">
        <header class="position-fixed">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 d-flex align-items-center">
                <Link onClick={() => navigate(-1)}>
                  <img className="customer_care_back_icon" src={BackImgIcon} />
                </Link>
                <h1 class="mb-0 ms-3 text-white h2 fw-normal">customer care</h1>
              </div>
            </div>
          </div>
        </header>

        <div class="main-content">
          <div class="container-fluid">
            <div className="customer_care_body">
              {/* <div className="customer_care_form_group">
            <label className="customer_care_input_label">Gamerji username</label>
            <input
              className="customer_care_input"
              placeholder="Enter your Gamerji username"
              onChange={(e) => setGamerjiName(e.target.value)}
              onBlur={() => filedValidation('username')}

            />
          </div> */}

              <div className="customer_care_form_group">
                <label className="customer_care_input_label">
                  mobile number*
                </label>
                <input
                  className="customer_care_input"
                  placeholder="Enter your mobile number"
                  onBlur={() => filedValidation("mobile")}
                  value={user?.userDeatils?.phone}
                />
              </div>

              <div className="customer_care_form_group">
                <label className="customer_care_input_label">email</label>
                <input
                  className="customer_care_input"
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                  // onBlur={() => filedValidation('email')}
                />
              </div>

              <div className="customer_care_form_group">
                <label className="customer_care_input_label">
                  enter your name*
                </label>
                <input
                  className="customer_care_input"
                  placeholder="Enter your name"
                  onChange={(e) => setName(e.target.value)}
                  onBlur={() => filedValidation("name")}
                  value={name}
                />
              </div>

              <div className="customer_care_form_group p-0">
                <label className="customer_care_input_label">category*</label>
                <Select
                  placeholder="select category"
                  value={selectedOption}
                  // onChange={(data) => { console.log(data) }}
                  onChange={(e) => changeCategory(e)}
                  options={categoryData}
                  styles={customStyles}
                  isClearable={true}
                  components={{ DropdownIndicator }}
                  isSearchable={false}
                />
              </div>
              <div className="customer_care_form_group p-0">
                <label className="customer_care_input_label">
                  sub category*
                </label>
                <Select
                  placeholder="select sub category"
                  value={selectedSubcategoryOption}
                  // onChange={(data) => { console.log(data) }}
                  onChange={(e) => changeSubCategory(e)}
                  options={subCategoryData}
                  styles={customStyles}
                  isClearable={true}
                  components={{ DropdownIndicator }}
                  isSearchable={false}
                  menuPortalTarget={document.body}
                />
              </div>

              <div className="customer_care_form_group">
                <label className="customer_care_input_label_bottom">
                  max: 500 chars
                </label>
                <textarea
                  className="customer_care_textarea"
                  placeholder="describe your issue"
                  maxLength="500"
                  rows={3}
                  // onBlur={() => filedValidation('description')}
                  onChange={(e) => setIssue(e.target.value)}
                ></textarea>
              </div>

              <div className="customer_care_btn_component">
                <button
                  className="customer_care_btn"
                  disabled={isSubmitBtnDisabled}
                  style={{
                    backgroundColor: isSubmitBtnDisabled
                      ? "var(--color-silver)"
                      : "var(--color-red)",
                  }}
                  onClick={onClickSubmit}
                >
                  submit
                </button>
              </div>
            </div>
          </div>

          {/* {isVisible2 ? (
            <div
              id="Gamerji_CustomerCare_320x50"
              style={{
                // display: isVisible2 ? "block" : "none",
                position: "fixed",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                bottom: 0,
                left: 0,
                right: 0,
                color: "white",
              }}
            ></div>
          ) : (
            ""
          )} */}
          <div
            id="Gamerji_CustomerCare_320x50"
            style={{
              display: isVisible2 ? "block" : "none",
              position: "fixed",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              bottom: 0,
              left: 0,
              right: 0,
              color: "white",
            }}
          ></div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
