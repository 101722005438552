import axios from "axios";
import { resolve } from "./resolver";
import helper from "../common/Helper";


function customercare() {
    const customerCareAdd = async (data) => {
        let url = helper.viApiUrl() + 'customerCare/add'
        let token = localStorage.getItem('userAccess')
        return await resolve(
            axios({
                method: 'post',
                url: url,
                responseType: 'json',
                headers: helper.headers(token),
                data: data
            })  
                .then(response => response.data)
        )

    }

    const customerCareCategoryList = async (data) => {
        let url = helper.saasApiUrl() + 'customercare/category/list'
        let token = localStorage.getItem('userAccess')
        return await resolve(
            axios({
                method: 'post',
                url: url,
                responseType: 'json',
                headers: helper.headers(token),
                data:data
            })
                .then(response => response.data)
        )

    }

    const ticketList = async (data) => {
        let url = helper.viApiUrl() + 'customercare/list'
        let token = localStorage.getItem('userAccess')
        return await resolve(
            axios({
                method: 'post',
                url: url,
                responseType: 'json',
                headers: helper.headers(token),
                data: data
            })
                .then(response => response.data)
        )

    }


    const getChatById = async (chatId) => {
        let token = localStorage.getItem('userAccess')
        return await resolve(
            axios({
                method: 'get',
                url: helper.viApiUrl() + 'customercare/get/' + chatId,
                responseType: 'json',
                headers: helper.headers(token)
            })
                .then(response => response.data)
        )

    }

    const sendMessage = async (data,ticketId) => {
        let token = localStorage.getItem('userAccess')
        return await resolve(
            axios({
                method: 'post',
                url: helper.viApiUrl() + 'customercare/addConversations/'+ticketId,
                responseType: 'json',
                headers: helper.headers(token),
                data: data
            })
                .then(response => response.data)
        )

    }





    return {
        customerCareAdd,
        customerCareCategoryList,
        ticketList,
        getChatById,
        sendMessage

    }
}
const customerCareApi = customercare();
export default customerCareApi;