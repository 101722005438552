import axios from "axios";
import { resolve } from "./resolver";
import helper from "../common/Helper";
var CryptoJS = require("crypto-js");
const configKey = require("../configs.json")

function user() {

    const transectionList = async (data) => {
        let token = localStorage.getItem('userAccess')
        return await resolve(
            axios({
                method: 'post',
                url: helper.viApiUrl() + 'transaction/list',
                headers: helper.headers(token),
                data:data
            })
                .then(response => response.data)
        )

    }
   
    const getUserDetail = async (data) => {
        let token = localStorage.getItem('userAccess')
        return await resolve(
            axios({
                method: 'get',
                url: helper.viApiUrl() + 'users/get/'+encodeURIComponent(data),
                headers: helper.headers(token),
            })
                .then(response => response.data)
        )
    }


    const agreeTremsAndCondition = async (data) => {

        return await resolve(
            axios({
                method: 'post',
                url: helper.viApiUrl() + 'user/trems-and-condition',
                headers: helper.headers(),
                data:data
            })
                .then(response => response.data)
        )

    }
    return {
        transectionList,
        getUserDetail,
        agreeTremsAndCondition
    }


}
const userApi = user();
export default userApi;