import axios from "axios";
import { resolve } from "./resolver";
import helper from "../common/Helper";


function contest() {

    const listByUser = async (data) => {
        let token = localStorage.getItem('userAccess')
        let url = helper.saasApiUrl() + 'joinUserContest/list'
        return await resolve(
            axios({
                method: 'post',
                url: url,
                responseType: 'json',
                headers: helper.headers(token),
                data: data
            })
                .then(response => response.data)
        )

    }

    const upcomingContestList = async (data) => {
        let url = helper.saasApiUrl1() + 'vi/upcomingContest'
        let token = localStorage.getItem('userAccess')
        return await resolve(
            axios({
                method: 'post',
                url: url,
                responseType: 'json',
                headers: helper.headers(token),
                data: data
            })
                .then(response => response.data)
        )

    }
    const lastplayedContestList = async (data) => {
        let token = localStorage.getItem('userAccess')
        let url = helper.saasApiUrl1() + 'vi/LastPlayed'
        console.log("API req:url ",url)
        return await resolve(
            axios({
                method: 'post',
                url: url,
                responseType: 'json',
                headers: helper.headers(token),
                data: data
            })
                .then(response => response.data)
        )

    }
    const listByGame = async (data) => {
        return await resolve(
            axios({
                method: 'post',
                url: helper.saasApiUrl() + 'contest/list',
                responseType: 'json',
                headers: helper.headers(),
                data:data
            })
                .then(response => response.data)
        )

    }
    const contestlistbygame = async (data) => {
        return await resolve(
            axios({
                method: 'post',
                url: helper.saasApiUrl() + 'vi/contestList',
                responseType: 'json',
                headers: helper.headers(),
                data:data
            })
                .then(response => response.data)
        )

    }

    const joinContest = async (data) => {
        let token = localStorage.getItem('userAccess')
        return await resolve(
            axios({
                method: 'post',
                url: helper.saasApiUrl() + 'joinUserContest/join/',
                responseType: 'json',
                headers: helper.headers(token),
                data: data
            })
                .then(response => response.data)
        )

    }
    const getContestById = async (contestId, userId = '') => {
        let data ={}
        data.search = {}
        //comment this userId becaususe now its going in token no need to send in body
        // data.search.user = userId
        let token = localStorage.getItem('userAccess')
        return await resolve(
            axios({
                method: 'post',
                url: helper.saasApiUrl() + 'contest/get/' + contestId,
                responseType: 'json',
                headers: helper.headers(token),
                data:data
            })
                .then(response => response.data)
        )

    }
    const getContestUserList = async (contestId,data) => {

       
        return await resolve(
            axios({
                method: 'post',
                url: helper.saasApiUrl() + 'joinedContestUserByContestId/' + contestId,
                responseType: 'json',
                headers: helper.headers(),                
                data:data
            })
                .then(response => response.data)
        )

    }

    return {
        upcomingContestList,
        listByUser,
        listByGame,
        joinContest,
        getContestById,
        getContestUserList,
        lastplayedContestList,
        contestlistbygame
    }
}
const contestApi = contest();
export default contestApi;